import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'valientes',
    loadChildren: () => import('./valientes/valientes.module').then( m => m.ValientesPageModule)
  },
  {
    path: 'nuevo-valiente',
    loadChildren: () => import('./valientes/nuevo-valiente/nuevo-valiente.module').then( m => m.NuevoValientePageModule)
  },
 
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'turno/:valientes[]',
    loadChildren: () => import('./valientes/turno/turno.module').then( m => m.TurnoPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
