import { Component, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';  
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { filter } from 'rxjs/operators';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(public router: Router, private platform: Platform,private ga: GoogleAnalytics) {
    this.initializeApp();

    const navEndEvents$ = this.router.events.pipe(filter(event=>event instanceof NavigationEnd));

    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-Z55QR039XW', {
          'page_path': event.urlAfterRedirects
      });
    });

  }

  initializeApp(){
    this.platform.ready().then(() => {
      this.router.navigateByUrl('splash');

      /*this.ga.startTrackerWithId('3325119257').then(() => {
      console.log('Google analytics is ready now');
      this.ga.trackView('Outbox').then(() => {}).catch(error => console.log(error));  
        }).catch(error => console.log('Google Analytics Error: ' + error));*/
    });

  }

   /*
   trackEvent(val) {
    this.ga.trackEvent('Category', 'Action', 'Label', val)
  }*/  
}
